import BaseBean from "@/utils/BaseBean";

export interface IVideoTypeListDataObj {
    utilInst:VideoTypeListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class VideoTypeListUtil extends BaseBean{
    public dataObj:IVideoTypeListDataObj

    constructor(proxy:any,dataObj:IVideoTypeListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}